import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components/macro";
import Dropzone from "./Dropzone";
import ArquivosService from "../../services/ArquivosService";
import BancoService from "../../services/BancoService";
import moment from "moment";

import BancoContext from "../../context/banco-context";

import LoadingIcon from "../LoadingIcon";
import { ReactComponent as AcceptIcon } from "../../icons/accept-icon.svg";
import { ReactComponent as CancelIcon } from "../../icons/cancel-icon.svg";

import { PillContainer, PillButton } from "../../theme/GlobalStyle";

import { ReactComponent as ChevronIcon } from "../../icons/ChevronDown.svg";

import TableContainer from "./TableContainer";

import { store } from "react-notifications-component";
import "animate.css";
import { ToastNotification, notificationTopRight } from "../ToastNotification";

import Modal from "../Modal";
import { Link, useLocation } from "react-router-dom";

import { useInterval } from "usehooks-ts";

import Table from "../@DesignSystem/Table";
import ToggleButton from "../@DesignSystem/ToggleButton";
import SwitchButton from "../@DesignSystem/SwitchButton";
import Title from "../@DesignSystem/Title";
import Button from "../@DesignSystem/Button";
import UploadComCertificado from "./UploadComCertificado";
import { useEffectDebugger } from "../../hooks/useEffectDebugger";
import InlineMessage from "../@DesignSystem/InlineMessage";
import StyledLink from "../@DesignSystem/StyledLink";
import Dropdown from "../@DesignSystem/Dropdown";

const StyledH1 = styled.h1`
  font-size: 32px;
  text-align: center;
  margin-top: 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 3rem;

  h2 {
    font-size: 2.5rem;
    margin-right: 2rem;

    span {
      margin-left: 1rem;
      font-size: 0.9em;
      color: ${(props) => props.theme.gray};
    }
  }
`;

const TituloTabela = styled.h3`
  font-size: 1.5rem;
`;

const DropUpload = styled.div`
  width: 100%;
  margin: 0 auto;

  .zip {
    text-align: center;

    p {
      font-size: 1.25rem;
      cursor: pointer;
      margin-top: 2rem;
      display: block;
    }

    svg {
      margin-left: 0.5rem;
      transform: ${(props) =>
        props.uploadZip
          ? "rotate(180deg) translateY(-4px)"
          : "rotate(0deg) translateY(4px)"};
    }
  }
`;

const DownloadTabela = styled.div`
  text-align: left;
  margin-top: 32px;

  button {
    background: ${(props) => props.theme.primary};
    border: none;
    padding: 0.75em 2em;
    border-radius: 0;
    cursor: pointer;
    color: white;
    font-size: 1rem;
    transition: all 0.3s ease;
    display: inline-block;
    box-shadow: 0px 10px 15px -10px rgba(0, 70, 215, 0.75);
    text-decoration: none;
    border-radius: 4px;

    &:hover {
      transform: translateY(-4px);

      box-shadow: 0px 10px 15px -5px rgba(0, 70, 215, 0.75);
    }
  }
`;

const InputZip = styled.input`
  display: none;
`;

const CarregandoZip = styled.p`
  span {
    margin-left: 1rem;
  }
`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function UploadContent(props) {
  const [isModalUploadOpen, setisModalUploadOpen] = useState(false);
  const [fileProgress, setfileProgress] = useState(0);
  const [arquivosTabela, setarquivosTabela] = useState([]);
  const [linkTrocaBanco, setlinkTrocaBanco] = useState("");
  const [arquivosCarregados, setarquivosCarregados] = useState(0);
  const [arquivosNaFila, setarquivosNaFila] = useState(0);
  const [numArquivosTotais, setnumArquivosTotais] = useState(0);
  const [zipPorcentagem, setzipPorcentagem] = useState(0);
  const [zipNome, setzipNome] = useState("");
  const [uploadingZip, setuploadingZip] = useState(false);
  const [finishedZip, setfinishedZip] = useState(false);
  const [uploadZip, setuploadZip] = useState(false);
  const [errorZip, seterrorZip] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [comCertificado, setComCertificado] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalArquivos, setTotalArquivos] = useState(0);
  const [dataError, setDataError] = useState(false);
  const [tablePage, setTablePage] = useState(0);

  const fetchIdRef = React.useRef(0);

  const inputFile = useRef(null);

  let query = useQuery();

  useInterval(
    () => {
      // Your custom logic here
      console.log("useInterval");
      if (props.banco === "database") {
        pegaArquivosBanco();
      } else {
        pegaArquivosStorage();
      }
    },
    // Delay in milliseconds or null to stop it
    isUpdating ? 20000 : null
  );

  // useEffect(() => {
  //   if (window.atualizaStatus) {
  //     clearInterval(window.atualizaStatus);
  //   }
  //   window.atualizaStatus = setInterval(atualizaStatusArquivo, 20000);
  //   if (props.banco === "database") {
  //     pegaArquivosBanco();
  //   } else {
  //     pegaArquivosStorage();
  //     if (window.Conpass) {
  //       // console.log("conpass arquivos");
  //       // window.Conpass.startFlow("prjKmaq8q1bVh");
  //     }
  //   }

  //   //  return () => {
  //   //    clearInterval(window.atualizaStatus);
  //   //  };
  // }, []);

  const updateComponent = () => {
    let banco = props.banco === "database" ? "storage" : "database";
    setLoadingData(true);
    setarquivosTabela([]);
    setlinkTrocaBanco(`/${banco}/${props.arquivo}/`);

    if (props.banco === "database") {
      pegaArquivosBanco();
    } else {
      pegaArquivosStorage();
    }
  };

  useEffect(() => {
    updateComponent();
  }, [props.arquivo, props.banco]);

  useEffect(() => {
    console.log(arquivosTabela);
  }, [arquivosTabela]);

  useEffect(() => {
    setTablePage(parseInt(query.get("page") || 0));
  }, [query]);

  // CNPJ: "00000000000000"
  // DATA_FULL: "2013-10-01T00:00:00.000Z"
  // DT_EMISSAO: "10/2013"
  // ID: 0
  // NOME: "Inve Aquaculture INC"
  // QTD: 3
  // TIPO_XML: "NFE_ENTRADA"

  const calculaTotalArquivosCarregados = (data) => {
    let arrayQtdArquivos = data.map((arquivo) => arquivo.QTD);
    let totalArquivos = arrayQtdArquivos.reduce(
      (partialSum, a) => partialSum + a,
      0
    );

    return totalArquivos;
  };

  // useEffectDebugger(() => {
  //   console.log("...");
  // }, [isUpdating, arquivosTabela, loadingData]);

  const fetchDataBanco = useCallback(
    ({ pageIndex = 0, pageSize, type, filtro, filtroNome }) => {
      console.log(filtroNome);
      let filtroHeader;
      if (filtro !== undefined && filtroNome !== undefined) {
        filtroHeader = { "status-filtro": filtro, "nome-filtro": filtroNome };
      } else if (filtro !== undefined && filtroNome === undefined) {
        filtroHeader = { "status-filtro": filtro };
      } else if (filtro === undefined && filtroNome !== undefined) {
        filtroHeader = { "nome-filtro": filtroNome };
      } else {
        filtroHeader = {};
      }
      if (props.banco !== "database") return;
      setLoadingData(true);
      let qtdGetArquivos = 1000;
      const fetchId = ++fetchIdRef.current;

      // console.log(arquivosTabela);
      console.log("fetchDataBanco is being called");

      // console.log(qtdGetArquivos, fetchId);
      // console.log(totalArquivos);
      // console.log(numArquivosTotais);

      BancoService.getInfoArquivos(
        props.tokenLogin,
        props.arquivo,
        0,
        1,
        filtroHeader
      )
        .then((resp1) => {
          console.log(resp1);
          if ((tablePage + 1) * qtdGetArquivos > resp1.data.descritores.total) {
            console.log("overload");
            qtdGetArquivos =
              resp1.data.descritores.total - (tablePage - 1) * qtdGetArquivos;
            if (qtdGetArquivos < 0) qtdGetArquivos = 1000;
          }

          let step = type === "nextPage" ? 1 : 0;

          if (fetchId === fetchIdRef.current) {
            BancoService.getInfoArquivos(
              props.tokenLogin,
              props.arquivo,
              tablePage + step,
              qtdGetArquivos || 1,
              filtroHeader
            )
              .then((resp) => {
                console.log(resp);

                let arquivosBanco;

                if (props.isXML) {
                  arquivosBanco = resp.data.descritores.arquivos.map(
                    (arquivo) => ({
                      id: arquivo.ID,
                      file_id: arquivo.ID,
                      NOME_EMPRESA: arquivo.NOME
                        ? arquivo.NOME
                        : `${arquivo.nome_arquivo} - falha ao carregar`,
                      DT_INI: formatDataArquivo(arquivo.DT_EMISSAO),
                      ...arquivo,
                    })
                  );
                } else {
                  arquivosBanco = resp.data.descritores.arquivos.map(
                    (element, i) => {
                      let dt_ini_formated = moment(
                        element.DT_INI.substring(0, 10)
                      ).locale("pt-br");
                      element.DT_INI = dt_ini_formated.format("DD/MM/yyyy");
                      let dt_fin_formated = moment(
                        element.DT_FIN.substring(0, 10)
                      ).locale("pt-br");
                      element.DT_FIN = dt_fin_formated.format("DD/MM/yyyy");
                      element.NOME_EMPRESA = element.NOME;
                      element.status = 3;
                      element.Status_Arquivo = status(element.status);
                      return element;
                    }
                  );
                }

                let updating = arquivosBanco.some(
                  (r) =>
                    r.status == 1 ||
                    r.status == 2 ||
                    r.status == 5 ||
                    r.status == 6
                );

                if (type === "pegaArquivosBanco" || tablePage <= 0)
                  setarquivosTabela(arquivosBanco);
                else
                  setarquivosTabela((current) => [
                    ...current,
                    ...arquivosBanco,
                  ]);

                setIsUpdating(updating);

                setTotalArquivos(resp.data.descritores.total);
                setPageCount(Math.ceil(resp.data.descritores.total / pageSize));
                setnumArquivosTotais(resp.data.descritores.total);
                setLoadingData(false);
                setDataError(false);
              })
              .catch((err) => {
                console.log(err);
                setDataError(true);
                setarquivosTabela([]);
                setLoadingData(false);
                setnumArquivosTotais(0);
              });
          }
        })
        .catch((err) => {
          console.log(err);

          setarquivosTabela([]);
          setLoadingData(false);
          setnumArquivosTotais(0);
        });
    },
    [props.arquivo, props.banco, tablePage]
  );

  const pegaArquivosBanco = () => {
    fetchDataBanco({ type: "pegaArquivosBanco" });
    return;
  };

  function formatDataArquivo(data) {
    let dt_emissao = data.split("-");
    return dt_emissao[1] + "/" + dt_emissao[0];
  }

  const status = (n) =>
    ({
      0: "Pronto para carregar",
      1: "Na fila de carregamento",
      2: "Carregando",
      3: "Carregado",
      4: "Falha no carregamento",
      5: "Na fila para apagar",
      6: "Apagando",
      7: "Corrompido",
      8: "Inválido",
      9: "Duplicado",
      10: "Cheio",
    }[n] || "");

  const fetchData = useCallback(
    ({ pageIndex = 0, pageSize, type, filtro, filtroNome }) => {
      console.log(filtroNome);
      let filtroHeader;
      console.log(filtro, filtroNome);
      if (filtro !== undefined && filtroNome !== undefined) {
        filtroHeader = { "status-filtro": filtro, "nome-filtro": filtroNome };
      } else if (filtro !== undefined && filtroNome === undefined) {
        filtroHeader = { "status-filtro": filtro };
      } else if (filtro === undefined && filtroNome !== undefined) {
        filtroHeader = { "nome-filtro": filtroNome };
      } else {
        filtroHeader = {};
      }
      if (props.banco !== "storage") return;
      setLoadingData(true);
      let qtdGetArquivos = 1000;
      const fetchId = ++fetchIdRef.current;

      // console.log(arquivosTabela);
      console.log("fetchData is being called");

      // console.log(qtdGetArquivos, fetchId);
      // console.log(totalArquivos);
      // console.log(numArquivosTotais);

      ArquivosService.getArquivosStorage(
        props.tokenLogin,
        props.arquivo,
        0,
        1,
        filtroHeader
      )
        .then((resp1) => {
          console.log(resp1);
          if ((tablePage + 1) * qtdGetArquivos > resp1.data.descritores.total) {
            console.log("overload");
            qtdGetArquivos =
              resp1.data.descritores.total - (tablePage - 1) * qtdGetArquivos;
            if (qtdGetArquivos < 0) qtdGetArquivos = 1000;
          }

          let step = type === "nextPage" ? 1 : 0;

          if (fetchId === fetchIdRef.current) {
            ArquivosService.getArquivosStorage(
              props.tokenLogin,
              props.arquivo,
              tablePage + step,
              qtdGetArquivos || 1,
              filtroHeader
            )
              .then((resp) => {
                console.log(resp);
                console.log(filtroHeader);
                let arquivosBanco = resp.data.descritores.arquivos
                  .filter((arquivo) => arquivo.status_storage > 0)
                  .map((arquivo) => ({
                    upload_date: arquivo.data_upload,
                    Status_Arquivo: status(arquivo.status_bi),
                    ...arquivo,
                  }));

                if (type === "pegaArquivosStorage" || tablePage <= 0)
                  setarquivosTabela(arquivosBanco);
                else
                  setarquivosTabela((current) => [
                    ...current,
                    ...arquivosBanco,
                  ]);

                let updating = arquivosBanco.some(
                  (r) =>
                    r.status_bi == 1 ||
                    r.status_bi == 2 ||
                    r.status_bi == 5 ||
                    r.status_bi == 6
                );

                setIsUpdating(updating);

                setTotalArquivos(resp.data.descritores.total);
                setPageCount(Math.ceil(resp.data.descritores.total / pageSize));
                setnumArquivosTotais(resp.data.descritores.total);
                setLoadingData(false);
                setDataError(false);
              })
              .catch((err) => {
                console.log(err);
                setDataError(true);
                setarquivosTabela([]);
                setLoadingData(false);
                setnumArquivosTotais(0);
              });
          }
        })
        .catch((err) => {
          console.log(err);

          setarquivosTabela([]);
          setLoadingData(false);
          setnumArquivosTotais(0);
        });
    },
    [props.arquivo, props.banco, tablePage]
  );

  const baixarCSV = () => {
    if (totalArquivos === 0) {
      alert("Não é possível baixar dados de uma tabela vazia");
      return;
    }

    if (props.banco === "database") {
      if (props.isXML) {
        BancoService.getInfoArquivos(props.tokenLogin, props.arquivo)
          .then((resp) => {
            // console.log(resp);

            let arquivosBanco = resp.data.arquivos.map((arquivo) => ({
              id: arquivo.ID,
              file_id: arquivo.ID,
              NOME_EMPRESA: arquivo.NOME
                ? arquivo.NOME
                : `${arquivo.nome_arquivo} - falha ao carregar`,
              DT_INI: formatDataArquivo(arquivo.DT_EMISSAO),
              ...arquivo,
            }));

            criaArquivoCSV(arquivosBanco);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        BancoService.getInfoArquivos(props.tokenLogin, props.arquivo)
          .then((resp) => {
            // console.log(resp);
            let arquivosBanco = resp.data.arquivos.map((element, i) => {
              let dt_ini_formated = moment(
                element.DT_INI.substring(0, 10)
              ).locale("pt-br");
              element.DT_INI = dt_ini_formated.format("DD/MM/yyyy");
              let dt_fin_formated = moment(
                element.DT_FIN.substring(0, 10)
              ).locale("pt-br");
              element.DT_FIN = dt_fin_formated.format("DD/MM/yyyy");
              element.NOME_EMPRESA = element.NOME;
              element.status = 3;
              element.Status_Arquivo = status(element.status);
              return element;
            });

            criaArquivoCSV(arquivosBanco);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    ArquivosService.getArquivosStorage(
      props.tokenLogin,
      props.arquivo,
      0,
      totalArquivos
    )
      .then((resp) => {
        let arquivosBanco = resp.data.descritores.arquivos
          .filter((arquivo) => arquivo.status_storage > 0)
          .map((arquivo) => ({
            // upload_date: arquivo.data_upload,
            nome_arquivo: arquivo.nome_arquivo,
            status_arquivo: status(arquivo.status_bi),
            tipo_arquivo: arquivo.tipo_arquivo,
            tamanho: arquivo.tamanho,
            data_upload: arquivo.data_upload,
          }));
        criaArquivoCSV(arquivosBanco);
      })
      .catch((err) => {
        console.log(err);
        alert("Não foi possível fazer o download dos dados da tabela");
      });
  };

  const criaArquivoCSV = (items) => {
    const filename = `${props.tipoArquivo}-${props.banco}.csv` || `export.csv`;
    const d = `;`;

    const header = Array.from(
      new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], []))
    );
    let csv = items.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName] || "")).join(d)
    );
    csv.unshift(header.join(d));
    csv = csv.join("\r\n");

    const blob = new Blob([csv], {
      type: "text/plain;charset=utf-8",
    });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
      return;
    }

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const pegaArquivosStorage = () => {
    console.log("pegaArquivosStorage");
    fetchData({ type: "pegaArquivosStorage" });
  };

  const callbackFunction = (childData) => {
    setfileProgress(childData);
  };

  const handleDrag = () => {
    if (!props.isLeitor) {
      setisModalUploadOpen(true);
    }
  };

  const toggleUploadModal = () => {
    if (props.banco === "database" && isModalUploadOpen) {
      props.history.push(`/storage/${props.arquivo}`);
    } else {
      updateComponent();
    }
    setisModalUploadOpen(!isModalUploadOpen);
  };

  // handleDrop = (acceptedFiles) => {
  //   //console.log(acceptedFiles);
  // };

  const atualizaStatusArquivo = () => {
    //console.log("-");
    // let update = () => {
    //   this.setState({ loading: true });
    //   this.setState({ loading: false });
    // };
    if (props.banco === "database") {
      let updating = arquivosTabela.some(
        (r) => r.status == 1 || r.status == 2 || r.status == 5 || r.status == 6
      );
      if (updating) {
        pegaArquivosBanco();
        console.log("database");
        // update();
      }
    } else {
      let updating;
      updating = arquivosTabela.some(
        (r) =>
          r.status_bi == 1 ||
          r.status_bi == 2 ||
          r.status_bi == 5 ||
          r.status_bi == 6
      );
      if (updating) {
        pegaArquivosStorage();
        console.log("storage");
        // update();
      }
    }
  };

  const uploadZipFn = (tokenLogin, tipoArquivo, nomeArquivo, arquivo) => {
    let that = this;
    new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      /* xhr.open(
        "POST",
        `${process.env.REACT_APP_URL_KUBERNETS}/api/comum/arquivos`
      );

//      console.log(process.env.MODULO_ARQUIVOS_URL);*/

      // console.log(tipoArquivo);
      // console.log(nomeArquivo);
      xhr.open("POST", process.env.REACT_APP_UPLOAD_URL + "/zip");
      xhr.setRequestHeader("tipo-arquivo", tipoArquivo);
      xhr.setRequestHeader("nome-arquivo", nomeArquivo);
      xhr.setRequestHeader("Authorization", "Bearer " + tokenLogin);

      xhr.onerror = function () {
        console.log("-----------------------error");
      };

      xhr.ontimeout = function () {
        console.log("-----------------------timeout");
      };

      xhr.onabort = function () {
        console.log("------------------------abort");
      };
      xhr.upload.onloadstart = () => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Fazendo upload de arquivo .zip... Permaneça na página até o fim do
              upload{" "}
            </ToastNotification>
          ),
          onRemoval: (id, removedBy) => console.log(""),
          ...notificationTopRight,
        });
      };

      xhr.upload.onprogress = function progressFunction(e) {
        if (e.lengthComputable) {
          let percentage = (e.loaded / e.total) * 100;

          setzipPorcentagem(percentage.toFixed(0));
          setuploadingZip(true);
          setfinishedZip(false);
          setzipNome(nomeArquivo);

          if (percentage === 100) {
            console.log("arquivo " + nomeArquivo + " finalizado");

            props.checkOperacao(3000);
            setfinishedZip(true);
          }
          /*     if (e.loaded === e.total) {
            setTimeout(() => {
              contadorArquivosUpados += 1;
              setCarregados(contadorArquivosUpados);
              obj.sucesso = true;
              obj.tryAgain = false;
              return resolve(obj);
            }, 5000);
          }*/
        }
      };

      xhr.onload = function () {
        try {
          let resp = JSON.parse(xhr.responseText);
          // console.log(resp);
          if (resp.codigo === 201) {
            store.addNotification({
              content: (
                <ToastNotification bg="primary" textoAuxiliar="">
                  Upload de arquivo .zip finalizado!
                </ToastNotification>
              ),
              // onRemoval: (id, removedBy) => //console.log(removedBy),
              ...notificationTopRight,
            });
          } else if (resp.codigo === 400) {
            store.addNotification({
              content: (
                <ToastNotification bg="secondary" textoAuxiliar="">
                  Não foi possível fazer o upload pois a extensão do arquivo
                  selecionado não era .zip{" "}
                </ToastNotification>
              ),
              // onRemoval: (id, removedBy) => //console.log(removedBy),
              ...notificationTopRight,
            });
          } else if (resp.codigo === 409) {
            seterrorZip(true);

            store.addNotification({
              content: (
                <ToastNotification bg="secondary" textoAuxiliar="">
                  Não é permitido o upload de mais de um zip ao mesmo tempo.
                  Espere a finalização do upload atual para começar outro{" "}
                </ToastNotification>
              ),
              // onRemoval: (id, removedBy) => //console.log(removedBy),
              ...notificationTopRight,
            });
          } else if (resp.codigo === 403) {
            reject("Limite");
          } else if (resp.codigo === 500) {
            store.addNotification({
              content: (
                <ToastNotification bg="secondary" textoAuxiliar="">
                  Ocorreu uma falha no servidor, por favor tente novamente{" "}
                </ToastNotification>
              ),
              // onRemoval: (id, removedBy) => //console.log(removedBy),
              ...notificationTopRight,
            });
          } else {
            //
          }
        } catch (error) {
          // console.log(error);
        }
      };
      xhr.send(arquivo);
    });
    //Limpa value do input por causa do onChange
    inputFile.current.value = "";
  };

  const handleSubmitZip = (e) => {
    const {
      target: { files },
    } = e;
    // console.log(files);
    if (!files[0]) {
      return;
    }
    if (!files[0].name.endsWith(".zip")) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Não foi possível fazer o upload pois a extensão do arquivo
            selecionado não era .zip{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
    } else {
      setTimeout(() => {
        props.checkOperacao();
      }, 2000);
      uploadZipFn(props.tokenLogin, props.arquivo, files[0].name, files[0]);
    }
  };

  const handleChangeView = (index) => {
    setComCertificado(index === 1);
  };

  const REINF = [
    { label: "1000", value: "1000" },
    { label: "1050", value: "1050" },
    { label: "1070", value: "1070" },
    { label: "2010", value: "2010" },
    { label: "2020", value: "2020" },
    { label: "2030", value: "2030" },
    { label: "2040", value: "2040" },
    { label: "2050", value: "2050" },
    { label: "2055", value: "2055" },
    { label: "2060", value: "2060" },
    { label: "2098", value: "2098" },
    { label: "2099", value: "2099" },
    { label: "3010", value: "3010" },
    { label: "4010", value: "4010" },
    { label: "4020", value: "4020" },
    { label: "4040", value: "4040" },
    { label: "4080", value: "4080" },
    { label: "4099", value: "4099" },
    { label: "9000", value: "9000" },
    { label: "9001", value: "9001" },
    { label: "9005", value: "9005" },
    { label: "9011", value: "9011" },
  ];
  //REINF: { "1000", "1050", "1070", "2010", "2020", "2030", "2040", "2050", "2055", "2060", "2098", "2099", "3010", "4010", "4020", "4040", "4080", "4099", "9000", "9001", "9005", "9011"}
  //ESOCIAL: { "1000", "1005", "1010", "1020", "1030", "1035", "1040", "1050", "1060", "1070", "1080", "1200", "1202", "1207", "1210", "1250", "1260", "1270", "1280", "1295", "1298", "1299", "1300", "2190", "2200", "2205", "2206", "2210", "2220", "2221", "2230", "2231", "2240", "2245", "2250", "2260", "2298", "2299", "2300", "2306", "2399", "2400", "2405", "2410", "2416", "2418", "2420", "2500", "2501", "3000", "5001", "5002", "5003", "5011", "5012", "5013", "5501", "8299" }
  return (
    <BancoContext.Consumer>
      {(temBanco) =>
        !temBanco ? (
          <StyledH1>Você não tem banco vinculado à sua conta</StyledH1>
        ) : (
          <div onDragEnter={handleDrag}>
            <HeaderContainer>
              <h2>
                Arquivos <b>{props.tipoArquivo}</b>
              </h2>
              {/* <Dropdown
                placeholder="Seleciona o tipo"
                width={200}
                onChange={(selectedOption) =>
                  setFilterExpired(selectedOption.value)
                }
                options={REINF}
              /> */}
            </HeaderContainer>
            <TituloTabela>
              <span>
                Total de {props.isXML ? "arquivos: " : "arquivos: "}{" "}
                {numArquivosTotais}{" "}
              </span>
            </TituloTabela>
            <PillContainer rounded>
              <PillButton
                rounded
                active={props.banco === "storage"}
                onClick={() => props.history.push(`/storage/${props.arquivo}/`)}
              >
                Armazenados
              </PillButton>
              <PillButton
                rounded
                active={props.banco === "database"}
                onClick={() =>
                  props.history.push(`/database/${props.arquivo}/`)
                }
              >
                Carregados no Dashboard
              </PillButton>
            </PillContainer>
            {/* <p>Carregando arquivo {this.state.arquivosCarregados} de {this.state.arquivosNaFila}...</p> */}{" "}
            {/* {memoizedTable} */}
            <TableContainer
              handleClick={toggleUploadModal}
              banco={props.banco}
              data={arquivosTabela}
              numArquivosTotais={numArquivosTotais}
              tokenLogin={props.tokenLogin}
              tipoArquivo={props.arquivo}
              updateComponent={updateComponent}
              temOperacao={props.temOperacao}
              operacaoEmAndamento={props.operacaoEmAndamento}
              checkOperacao={props.checkOperacao}
              isLeitor={props.isLeitor}
              isXML={props.isXML}
              loadingData={loadingData}
              fetchData={
                props.banco === "database" ? fetchDataBanco : fetchData
              }
              totalArquivos={totalArquivos}
              pageCount={pageCount}
              propDataError={dataError}
            />
            <Button mt={16} ghost color="primary" onClick={baixarCSV}>
              Baixar .csv da tabela
            </Button>
            {isModalUploadOpen && (
              <Modal
                id="modal"
                isOpen={isModalUploadOpen}
                onClose={toggleUploadModal}
                modalSize="lg"
                modalTipo="primary"
                pgBackground="primary"
                progress={fileProgress}
              >
                <Title headingLevel="h5">Carregar arquivos</Title>
                {(props.arquivo.toLowerCase() === "icms" ||
                  props.arquivo.toLowerCase() === "contrib" ||
                  props.arquivo.toLowerCase() === "contabil" ||
                  props.arquivo.toLowerCase() === "ecf") && (
                  <SwitchButton
                    variant={1}
                    selection={comCertificado ? 1 : 0}
                    handleChange={handleChangeView}
                    options={["do computador", "com certificado digital"]}
                  />
                )}
                <InlineMessage size="small">
                  Ao fazer upload de um arquivo você concorda com nossa{" "}
                  <StyledLink
                    to="https://taxcel.com.br/politica-de-privacidade"
                    external
                  >
                    Política de privacidade
                  </StyledLink>
                </InlineMessage>
                {comCertificado ? (
                  <UploadComCertificado
                    tokenLogin={props.tokenLogin}
                    arquivo={props.arquivo}
                    toggleUploadModal={() =>
                      setisModalUploadOpen(!isModalUploadOpen)
                    }
                    checkOperacao={props.checkOperacao}
                  />
                ) : (
                  <DropUpload uploadZip={uploadZipFn}>
                    {uploadingZip && (
                      <CarregandoZip>
                        {finishedZip ? (
                          <AcceptIcon className="icon" />
                        ) : (
                          <LoadingIcon />
                        )}
                        {finishedZip ? (
                          <span>Upload do arquivo {zipNome} finalizado!</span>
                        ) : (
                          <span>
                            {zipPorcentagem}% do arquivo {zipNome}
                          </span>
                        )}
                      </CarregandoZip>
                    )}
                    <Dropzone
                      parentCallBack={callbackFunction}
                      tipoArquivo={props.tipoArquivo}
                      arquivo={props.arquivo}
                      updateComponent={updateComponent}
                      toggleUploadModal={toggleUploadModal}
                      checkOperacao={props.checkOperacao}
                      temOperacao={props.temOperacao}
                    />
                    <div className="zip">
                      <>
                        <Button
                          id="upload-zip-button"
                          onClick={() => {
                            inputFile.current.click();
                          }}
                          mt={16}
                          center
                          background="primary"
                        >
                          Fazer upload de arquivo .zip
                        </Button>
                        <InputZip
                          ref={inputFile}
                          type="file"
                          id="zip"
                          name="zip"
                          accept=".zip"
                          multiple={false}
                          onChange={handleSubmitZip}
                        ></InputZip>
                      </>
                    </div>
                  </DropUpload>
                )}
              </Modal>
            )}{" "}
          </div>
        )
      }
    </BancoContext.Consumer>
  );
}

UploadContent.contextType = BancoContext;
