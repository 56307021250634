import React from "react";

const NotasAtt = () => {
  return (
    <div>
      <h3 className="title">Notas de atualização</h3>
    </div>
  );
};

export default NotasAtt;
